<template>
    <div class="flex flex-col-reverse lg:flex-row relative w-full">
        <div class="lg:w-3/4">
            <div class="max-w-md">
                <h2 id="help">How to Get Help</h2>
                <p>
                    If you have a question or need technical support, there are
                    several ways to get help:
                </p>
                <ul>
                    <li>
                        If you are in the Spooktacular and have questions or are
                        experiencing problems, you can chat with a support
                        person.
                        <strong
                            >Simply click on the chat icon in the bottom right
                            corner of your screen.</strong
                        >
                    </li>
                    <li>
                        <p>
                            You can access our virtual Information Desk. Here
                            you can speak with a staff member who can help you
                            with your tech setup, answer questions about the
                            meeting, and more.
                            <strong>
                                To visit the Information Desk,
                                <router-link to="/sessions"
                                    >check the schedule!</router-link
                                >
                            </strong>
                        </p>
                    </li>
                </ul>
                <h2 id="tech">Basic Tech Requirements</h2>
                <p>
                    If you can access the web, you can participate in
                    Spooktacular! However, to be successful we strongly
                    encourage using a PC or Mac with a keyboard, camera,
                    microphone, and strong internet connection.
                </p>
                <p>In general, here are the specific requirements:</p>
                <ul>
                    <li>
                        PC, Mac, tablet, or smartphone (although we recommend
                        using a PC or Mac)
                    </li>
                    <li>Internet connection (wired is better than wi-fi)</li>
                    <li>
                        Modern web browser. The Spooktacular will support the
                        latest versions of Chrome, Safari, Firefox, and Edge.
                        Internet Explorer is not fully supported and you should
                        use a different browser.
                    </li>
                    <li>
                        <strong
                            >Please upgrade to the latest version of your chosen
                            web browser. Although our system supports several
                            versions of Chrome and Firefox, we do not support
                            older versions of Edge or Internet Explorer.</strong
                        >
                    </li>
                    <li>
                        If using Microsoft Edge, please make sure you have the
                        latest version.
                        <a href="https://www.microsoft.com/en-us/edge"
                            >You can download it<span class="sr-only">
                                (the latest version of Microsoft Edge)
                            </span>
                            here.</a
                        >
                    </li>
                    <li>
                        Not sure if your browser is up-to-date? Check here:
                        <a href="https://www.whatismybrowser.com/"
                            >https://www.whatismybrowser.com/</a
                        >
                    </li>
                    <li>
                        An external or integrated microphone (external is
                        recommended). We recommend using a headset with built-in
                        microphone.
                    </li>
                    <li>
                        If you plan to participate in the Spooktacular from a
                        tablet or smartphone, know that some features will be
                        harder to access, although watching sessions will work
                        just fine.
                    </li>
                </ul>
                <h2 id="login">Logging In to the Spooktacular</h2>
                <ul>
                    <li>
                        <strong
                            >Please log in before October 29th so that you can
                            check your credentials and check your tech!</strong
                        >
                        You don’t want to miss even a minute of the
                        Spooktacular!
                    </li>
                    <li>
                        The first time you visit the Spooktacular, you will be
                        asked to enter your email address. Please enter the
                        email address you used to register for the Spooktacular.
                    </li>
                    <li>
                        At the email you’ve used to register, you should find a
                        password we’ve sent to you for logging in.
                    </li>
                    <li>
                        After you’ve logged in once, you will not be asked to
                        log in again. If, by chance, you do get logged out, or
                        if you want to access the Spooktacular from a different
                        device or browser, you will need to re-enter your email
                        and password.
                    </li>
                </ul>
                <h2 id="meetings">
                    Accessing the Spooktacular Meeting Rooms
                </h2>
                <p>
                    There’s a lot of tech going on at the Spooktacular.
                    <strong
                        >Please make sure you can access the demo rooms by
                        <a href="/sessions"
                            >clicking this link to visit the Information Desk</a
                        >.</strong
                    >
                    While there:
                </p>
                <ul>
                    <li>Make sure you can view the videos.</li>
                    <li>
                        Make sure you have given your browser permission to use
                        your speaker and microphone for the meeting.
                    </li>
                    <li>
                        You can use the Attendee Chat so that you can post
                        comments and question to the Demo Sponsor presenters.
                    </li>
                </ul>
                <h2 id="search">Attendee Search and Updating Your Profile</h2>
                <p>
                    During the Spooktacular, you can network with your fellow
                    attendees! The
                    <router-link to="/attendees"
                        >Attendee landing page</router-link
                    >
                    will show you a random collection of attendees. You can
                    click on a person’s name to get more information or click
                    Send a Message to send that a person a message. All messages
                    stay in the meeting, i.e., they don’t trigger emails.
                </p>
                <p>
                    Want to get more out of the Spooktacular? Update your
                    profile! You can upload, enter contact information, even
                    display your interests!
                </p>
                <h2 id="schedule">
                    Accessing the Schedule and Previewing the Case Studies
                </h2>
                <p>
                    We’ve got a packed schedule. Preview the schedule by going
                    to <router-link to="/sessions">Sessions</router-link>.
                </p>
                <ul>
                    <li>To view each day’s schedule, please go to Upcoming.</li>
                    <li>
                        To view the Case Study teaser videos and the the Case
                        Studies once they have aired, please click on Previously
                        Recorded.
                    </li>
                </ul>
                <p>
                    <strong class="text-lg"
                        >We are here to help you get the most out of the
                        conference, so please be in touch! Have a wonderful
                        Spooktacular conference!
                    </strong>
                </p>
            </div>
        </div>
        <div class="lg:w-1/4 lg:pl-8 mb-8">
            <ul>
                <li>
                    <a href="#help">How to Get Help</a>
                </li>
                <li>
                    <a href="#tech">Basic Tech Requirements</a>
                </li>
                <li>
                    <a href="#login">Logging In to the Spooktacular </a>
                </li>
                <li>
                    <a href="#meetings"
                        >Accessing the Spooktacular Meeting Rooms</a
                    >
                </li>
                <li>
                    <a href="#search"
                        >Attendee Search and Updating Your Profile</a
                    >
                </li>
                <li>
                    <a href="#schedule"
                        >Accessing the Schedule and Previewing the Case
                        Studies</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "NdapSpooktacular"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
